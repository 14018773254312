import React, { useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import BetaBadge from '../beta-badge';

import './style.css';

type ScrollDirection = 'up' | 'down' | null;

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);
  return scrollDirection;
};

interface HeaderProps {
  autoHide?: boolean,
}

const Header: React.FC<HeaderProps> = (props) => {
  const { autoHide = false } = props;

  const scrollDirection = useScrollDirection();
  const shouldHide = autoHide && scrollDirection === 'down';
  const isBeta = process.env.REACT_APP_BETA === 'true';

  return (
    <div className={`top-header ${shouldHide ? 'hide' : 'show'}`}>
      <strong>Coffee Club</strong>
      {isBeta && (<BetaBadge />)}
      <Link to="/search" className="search">
        <FontAwesomeIcon icon={faSearch} size="xl" />
      </Link>
    </div>
  );
};

export default Header;
