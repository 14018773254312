import React from 'react';
import { RatingChange, Rating as ReactRating } from '@smastrom/react-rating';

// workaround to fix import from NPM package.
import './react-rating.css';
import './style.css';

interface RatingProps {
  rating?: number,
  readOnly?: boolean,
  onChangeRating?: RatingChange,
}

const Rating = (props: RatingProps) => {
  const {
    rating = 0,
    readOnly = false,
    onChangeRating = undefined,
  } = props;
  return (
    <ReactRating
      style={{ maxWidth: 100 }}
      spaceBetween="medium"
      value={rating}
      readOnly={readOnly}
      onChange={onChangeRating}
    />
  );
};

export default Rating;
