import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartCircleMinus } from '@fortawesome/free-solid-svg-icons';

import { StoreContextType, useStore } from '../../store/store-context.tsx';
import { useAuth } from '../../context/Auth.tsx';
import { setFavorite, resetFavorite } from '../../store/feed-reducer.ts';
import { setFocus, resetFocus } from '../../services/api.ts';

import './style.css';

interface FavoriteButtonProps {
  feedId: number,
  secondary?: boolean,
}

const FavoriteButton = (props: FavoriteButtonProps) => {
  const {
    feedId,
    secondary = false,
  } = props;
  const { state: { favorite }, dispatch } = useStore() as StoreContextType;
  const { authToken } = useAuth() as AuthContextType;

  const isActive = favorite && favorite.feedId === feedId;

  const handleResetFavorite = () => {
    resetFocus(authToken)
      .then((response) => {
        if (!response.ok) {
          throw new Error('HTTP error', { cause: response });
        }
      })
      .then(() => dispatch(resetFavorite()));
  };

  const handleSetFavorite = () => {
    setFocus(authToken, feedId)
      .then((response) => {
        if (!response.ok) {
          throw new Error('HTTP error', { cause: response });
        }
        return response.json();
      })
      .then((data) => dispatch(setFavorite(data)));
  };

  return (
    <div className="favoriteButton">
      { isActive && (
      <Button variant={secondary ? 'outline-primary' : 'primary'} onClick={handleResetFavorite}>
        <FontAwesomeIcon icon={faHeartCircleMinus} />
        &nbsp;
        Verwijder Focus
      </Button>
      )}
      { !isActive && (
      <Button
        variant={secondary ? 'outline-primary' : 'primary'}
        onClick={handleSetFavorite}
      >
        <FontAwesomeIcon icon={faHeart} />
        &nbsp;
        Toevoegen
      </Button>
      )}
      { !isActive && favorite && (
      <div className="disclaimer">
        Huidige focus op
        &nbsp;
        <strong>{favorite.coffeeName}</strong>
        &nbsp;
        {favorite.brandName && (
        <span>
          van
          &nbsp;
          <strong>{favorite.brandName}</strong>
          &nbsp;
        </span>
        )}
        wordt overschreven.
      </div>
      )}
    </div>
  );
};

export default FavoriteButton;
