import React from 'react';
import Card from 'react-bootstrap/Card';
import TimeAgo from 'react-timeago';

import Rating from '../rating/index.ts';

import './style.css';

interface CoffeeCardProps {
  coffeeName: string,
  coffeeBrand: string,
  username: string,
  imageUrl?: string,
  comment: string,
  rating: number,
  openDateTime: Date,
  averageRating: number,
  ratingCount: number,
  onClick?: Props.ClickHandler,
}

const CoffeeCard = (props: CoffeeCardProps) => {
  const {
    coffeeName,
    coffeeBrand,
    username,
    imageUrl = undefined,
    comment,
    rating,
    openDateTime,
    averageRating,
    ratingCount,
    onClick = undefined,
  } = props;

  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };

  return (
    <Card className="coffeeCard" onClick={handleClick}>
      <div className="boxContainer">
        <div className="column-1 box">
          <div className="metaInfo">
            { coffeeBrand && <div className="coffeeBrand">{coffeeBrand}</div> }
            <div className="coffeeName">{coffeeName}</div>
          </div>
          <div className="userInfo">
            <span className="who">{username}</span>
            { comment && (
            <span className="comment">
              {comment}
            </span>
            )}
            { openDateTime && (
            <div className="when">
              <TimeAgo
                date={openDateTime}
                live={false}
              />
            </div>
            )}
          </div>
          <div className="rating">
            <Rating
              rating={rating}
              readOnly
            />
            { averageRating && (
            <span className="small">
              Gemiddeld
              &nbsp;
              {averageRating}
              &nbsp;
              (
              {ratingCount}
              &nbsp;
              beoordelingen)
            </span>
            )}
            { !averageRating && (
              <span className="small">&nbsp;</span>
            )}
          </div>
        </div>
        <div className="box">
          { imageUrl && (
          <div className="imageContainer">
            <img src={imageUrl} alt={coffeeName} />
          </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default CoffeeCard;
