import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-html5-camera-photo/build/css/index.css';
import './TakePhoto.css';

interface TakePhotoProps {
  onPhotoTaken?: Props.ClickHandler,

}

const TakePhoto = (props: TakePhotoProps) => {
  const [isCameraActive, setCameraActive] = useState(false);
  const [imageData, setImageData] = useState();
  const [isCameraError, setCameraError] = useState(false);

  const { onPhotoTaken = undefined } = props;

  const toggleCamera = () => {
    setCameraActive(!isCameraActive);
  };

  const deleteImage = () => {
    setImageData(undefined);
  };

  const handleTakePhoto = (dataUri: any) => {
    setImageData(dataUri);
    setCameraActive(false);
    onPhotoTaken(dataUri);
  };

  const handleCameraError = (error: any) => {
    console.log('handleCameraError', error);
    setCameraError(true);
    setCameraActive(false);
  };

  let content;
  if (isCameraError) {
    content = <div>Er is iets mis met de camera!</div>;
  } else if (imageData) {
    content = (
      <div className="preview">
        <div>
          <img src={imageData} alt="preview" />
        </div>
        <Button variant="outline-secondary" onClick={deleteImage}>Foto Verwijderen</Button>
      </div>
    );
  } else if (isCameraActive) {
    content = (
      <Camera
        onTakePhotoAnimationDone={(dataUri: any) => { handleTakePhoto(dataUri); }}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        isDisplayStartCameraError={false}
        onCameraError={(error: any) => { handleCameraError(error); }}
        isImageMirror={false}
      />
    );
  } else {
    content = (
      <Button variant="outline-primary" onClick={toggleCamera}>
        <FontAwesomeIcon icon={faCamera} />
        <span>Foto</span>
      </Button>
    );
  }

  return (
    <div className="photo">
      {content}
    </div>
  );
};

export default TakePhoto;
