import React from 'react';

import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

import './style.css';

interface ImageGalleryProps {
  images: string[],
}

const ImageGallery = (props: ImageGalleryProps) => {
  const { images } = props;
  const showControls = images.length > 1;

  return (
    <div className="galleryParent">
      <Carousel interval={null} controls={showControls} indicators={showControls}>
        {images.map((item) => (
          <Carousel.Item key={item.slice(-16)}>
            <Image src={item} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageGallery;
