import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';

import Header from '../../components/header/index.ts';
import Footer from '../../components/footer/index.ts';
import CoffeeCard from '../../components/coffee-card/index.ts';
import BeansCard from '../../components/beans-card/index.ts';
import FocusCard from '../../components/focus-card/focus-card.tsx';

import { useAuth, AuthContextType } from '../../context/Auth.tsx';
import { fetchFeed, fetchFocus } from '../../services/api.ts';
import { CheckInType } from '../../services/constants.ts';
import { StoreContextType, useStore } from '../../store/store-context.tsx';
import { setFavorite } from '../../store/feed-reducer.ts';

import './style.css';

const withTimer = (handler: () => void) => {
  const timer = setTimeout(handler, 500);
  return () => clearTimeout(timer);
};

// Copied from the internals of 'react-simple-pull-to-refresh' so the
// same loader can be triggered manually as well.
const Loader = () => (
  <div className="lds-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);

const EmptyFeed = () => (
  <div className="empty">
    <h3>Hier is niks te vinden...</h3>
    <p>
      Helaas is er nog geen data beschikbaar.
      Probeer te refreshen, en zie wat er gebeurd!
    </p>
  </div>
);

const ErrorFeed = (props: { errorMessage: string }) => {
  const { errorMessage } = props;
  return (
    <div className="empty">
      <h3>Fout tijdens laden...</h3>
      <p className="error">
        {errorMessage}
      </p>
      <p>
        Probeer te refreshen, en zie wat er gebeurd!
      </p>
    </div>
  );
};

const FeedOverview = () => {
  const [isLoading, setLoading] = useState(false);
  const [feedData, setFeedData] = useState<any[] | null>(null);
  const [isLoggedOut, setLoggedOut] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);

  const history = useHistory();
  const { authToken } = useAuth() as AuthContextType;
  const { state: { favorite }, dispatch } = useStore() as StoreContextType;

  const showDetail = (item: any) => {
    console.log(`show detail item: ${item.id}`);
    history.push(`/detail/${item.id}`);
  };

  const navigateToFocus = () => history.push('/focus');

  const reload = useCallback(() : Promise<any[]> => {
    console.log('starting reload');
    return Promise.all([
      new Promise((resolve, reject) => {
        if (authToken === null) {
          reject(new Error('missing auth token'));
        } else {
          fetchFeed(authToken)
            .then((response) => {
              if (!response.ok) {
                reject(new Error('HTTP error', { cause: response }));
              }
              return response.json();
            })
            .then((body) => {
              withTimer(() => {
                console.log('reload feed finished!');
                resolve(body);
              });
            });
        }
      }),
      new Promise((resolve, reject) => {
        if (authToken === null) {
          reject(new Error('missing auth token'));
        } else {
          fetchFocus(authToken)
            .then((response) => {
              if (!response.ok) {
                return reject(new Error('HTTP error', { cause: response }));
              }
              if (response.status === 204) {
                // nothing in-focus.
                return resolve(undefined);
              }
              return response.json();
            })
            .then((body) => {
              resolve(body);
            });
        }
      }),
    ]);
  }, [authToken]);

  const handleApiError = (error: Error) => {
    const cause = error.cause as Response;
    if (cause.status === 401) {
      // no token or expired.
      setLoggedOut(true);
    } else {
      setError(`[${cause.status}] ${error.message}`);
      setLoading(false);
    }
  };

  const handleRefresh = () => new Promise<void>((resolve, reject) => {
    console.log('triggered pull to refresh');
    reload()
      .then(([data, focus]) => {
        setFeedData(data);
        setFavorite(focus);
        if (focus) {
          dispatch(setFavorite(focus));
        }
        resolve();
      })
      .catch((error: Error) => {
        reject();
        handleApiError(error);
      });
  });

  useEffect(() => {
    if (feedData === null && !isLoading && !isError) {
      console.log('loading inital feed data');
      setLoading(true);
      reload()
        .then(([data, focus]) => {
          setFeedData(data);
          setFavorite(focus);
          if (focus) {
            dispatch(setFavorite(focus));
          }
          setLoading(false);
          setError(null);
        })
        .catch((error: Error) => handleApiError(error));
    }
  }, [isLoading, isError, feedData, reload, dispatch]);

  if (isLoggedOut) {
    return <Redirect to="/logout" />;
  }

  const isEmpty = !isError && feedData != null && feedData.length === 0;

  return (
    <div className="feed-overview">
      <Header />
      <main>
        {isLoading && <Loader />}
        <PullToRefresh
          pullingContent="pulling"
          onRefresh={handleRefresh}
        >
          <div className="feed-container">
            {isError && <ErrorFeed errorMessage={isError} />}
            {isEmpty && <EmptyFeed />}
            {!isEmpty && !isLoading && favorite && (
              <div
                key="focus"
                onClick={() => navigateToFocus()}
                aria-hidden="true"
              >
                <FocusCard
                  coffeeName={favorite.coffeeName}
                  coffeeBrand={favorite.brandName}
                  proSettings={favorite.proSettings !== undefined}
                />
              </div>
            )}
            {!isEmpty && feedData?.map((item: any) => {
              if (item.checkInType === CheckInType.BEANS) {
                return (
                  <div
                    key={item.id}
                    onClick={() => showDetail(item)}
                    aria-hidden="true"
                  >
                    <BeansCard
                      coffeeBrand={item.brand.name}
                      coffeeName={item.name}
                      roastDate={item.roastDate ? new Date(item.roastDate) : undefined}
                      imageUrl={item.image || item.productImage}
                    />
                  </div>
                );
              }
              /* Render legacy coffee-card, not aware of check-in type */
              return (
                <CoffeeCard
                  key={item.id}
                  coffeeBrand={item.brand.name}
                  coffeeName={item.name}
                  username={item.user.username}
                  imageUrl={item.image}
                  comment={item.comment}
                  openDateTime={new Date(item.dateAdd)}
                  rating={item.rating}
                  onClick={() => showDetail(item)}
                />
              );
            })}
          </div>
        </PullToRefresh>
      </main>
      <Footer />
    </div>
  );
};

export default FeedOverview;
