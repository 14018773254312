import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Spinner } from 'react-bootstrap';

import CoffeeCard from '../../coffee-card/index.ts';
import { AuthContextType, useAuth } from '../../../context/Auth.tsx';
import { adminSubmitApproval, adminSubmitSpam } from '../../../services/admin-api.ts';

import './style.css';

const withTimer = (handler: any) => {
  const timer = setTimeout(handler, 1000);
  return () => clearTimeout(timer);
};

const useSubmitData = () => {
  const [isLoading, setLoading] = useState(false);
  const [isApproved, setApproved] = useState(false);
  const [isSpam, setSpam] = useState(false);
  const [isError, setError] = useState(false);

  const { authToken } = useAuth() as AuthContextType;

  const submitApproval = (feedId: any, data: any) => {
    setLoading(true);
    setError(false);
    if (authToken) {
      adminSubmitApproval(authToken, feedId, data)
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res;
        })
        .then((res) => res.json())
        .then((body) => {
          withTimer(() => {
            setLoading(false);
            setApproved(true);
          });
        })
        .catch(() => {
          withTimer(() => {
            setLoading(false);
            setError(true);
          });
        });
    }
  };

  const submitSpam = (feedId: any) => {
    setLoading(true);
    setError(false);
    adminSubmitSpam(authToken, feedId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        withTimer(() => {
          setLoading(false);
          setSpam(true);
        });
      })
      .catch(() => {
        withTimer(() => {
          setLoading(false);
          setError(true);
        });
      });
  };

  return {
    isLoading,
    isError,
    isApproved,
    isSpam,
    submitApproval,
    submitSpam,
  };
};

interface UnapprovedCardProps {
  item: object,
}

const UnapprovedCard = (props: UnapprovedCardProps) => {
  const { item } = props;
  const [validated, setValidated] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [coffeeName, setCoffeeName] = useState('');

  const {
    isLoading,
    isError,
    isApproved,
    isSpam,
    submitApproval,
    submitSpam,
  } = useSubmitData();

  const handleClickSpam = (feedId) => {
    console.log(`spam: ${feedId}`);
    submitSpam(feedId);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.currentTarget.checkValidity() === true) {
      const data = {
        brandName,
        coffeeName,
      };
      submitApproval(item.id, data);
    }
    setValidated(true);
  };

  if (isSpam) {
    return (
      <div className="unapprovedCard done">
        <FontAwesomeIcon icon={faBan} size="2x" />
      </div>
    );
  }

  if (isApproved) {
    return (
      <div className="unapprovedCard done">
        <FontAwesomeIcon icon={faCheck} size="2x" />
      </div>
    );
  }

  return (
    <div className="unapprovedCard">
      <CoffeeCard
        coffeeBrand={item.brand.name}
        coffeeName={item.name}
        username={item.user.username}
        imageUrl={item.image}
        comment={item.comment}
        openDateTime={new Date(item.dateAdd)}
        rating={item.rating}
      />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="brandName">
          <Form.Control
            placeholder="Merk naam"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            disabled={isLoading}
            required
          />
        </Form.Group>
        <Form.Group controlId="coffeeName">
          <Form.Control
            placeholder="Koffie soort"
            value={coffeeName}
            onChange={(e) => setCoffeeName(e.target.value)}
            disabled={isLoading}
            required
          />
        </Form.Group>
        <div className="buttons">
          <Button variant="outline-success" type="submit" disabled={isLoading}>OK</Button>
          <Button variant="outline-danger" disabled={isLoading} onClick={() => handleClickSpam(item.id)}>SPAM</Button>
          <span className="feedback">
            {isLoading && (
              <Spinner animation="border" size="sm" />
            )}
            {isError && (
              <FontAwesomeIcon
                className="error"
                icon={faExclamationTriangle}
              />
            )}
          </span>
        </div>
      </Form>
    </div>
  );
};

export default UnapprovedCard;
