export interface FavoriteInfo {
  feedId: number,
  coffeeName: string,
  brandName: string,
  proSettings?: ProSettings,
}

export interface ProSettings {
  recipeId: number,
  grindSize: number,
  doseIn: number,
  yieldOut: number,
  extractionTime: number,
  notes?: string,
}

export interface StoreState {
  feed: any[];
  favorite: FavoriteInfo | undefined;
}

export const initialState: StoreState = {
  feed: [],
  favorite: undefined,
};

// ACTIONS

type AddCoffeeAction = {
  type: 'add_coffee',
  payload: any, // feed item
}

type SetFeedAction = {
  type: 'set_feed',
  payload: any[], // feed array
}

type SetFavoriteAction = {
  type: 'set_favorite',
  payload: FavoriteInfo,
}

type ResetFavoriteAction = {
  type: 'reset_favorite',
}

export type StoreAction =
  AddCoffeeAction |
  SetFeedAction |
  SetFavoriteAction |
  ResetFavoriteAction |
  SetFavoriteProSettingsAction;

// REDUCER METHODS

export const addCoffee = (payload: any) => ({
  type: 'add_coffee',
  payload,
});

export const setFeed = (payload: any[]) => ({
  type: 'set_feed',
  payload,
});

export const setFavorite = (payload: FavoriteInfo) => ({
  type: 'set_favorite',
  payload,
} as SetFavoriteAction);

export const resetFavorite = () => ({
  type: 'reset_favorite',
} as ResetFavoriteAction);

export const setFavoriteProSettings = (payload: ProSettings) => ({
  type: 'set_favorite_pro_settings',
  payload,
} as SetFavoriteProSettingsAction);

// REDUCER

export const feedReducer = (state: StoreState, action: StoreAction) => {
  switch (action.type) {
    case 'add_coffee':
      console.log(`add coffee to feed: ${JSON.stringify(action.payload)}`);
      return {
        ...state,
        feed: [action.payload].concat(state.feed),
      };
    case 'set_feed':
      console.log('set feed!');
      return {
        ...state,
        feed: action.payload,
      };
    case 'set_favorite':
      console.log('set favorite to', action.payload);
      return {
        ...state,
        favorite: action.payload,
      };
    case 'reset_favorite':
      console.log('reset favorite');
      return {
        ...state,
        favorite: undefined,
      };
    case 'set_favorite_pro_settings': {
      console.log('set pro-settings for favorite');
      const { favorite } = state;
      if (favorite) {
        favorite.proSettings = action.payload;
        return {
          ...state,
          favorite,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
