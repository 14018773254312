import React, { useState } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

import { logger } from '../logger';
import { AuthContextType, useAuth } from '../context/Auth.tsx';
import { login } from '../services/api.ts';
import IconAttribution from './icon-attribution/index.ts';

import logo from '../images/coffeecup-512.png';
import './Login.css';

interface LoginProps {
  location?: any,
}

const Login = (props: LoginProps) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);

  const { saveAuthResult } = useAuth() as AuthContextType;

  const { location = undefined } = props;
  const referer = (location && location.state && location.state.referer) || '/';

  function updateLoginResult(res: any) {
    saveAuthResult(res);
    setLoading(false);
    setLoggedIn(true);
  }

  const sendLogin = () => {
    setLoading(true);
    login(email, password)
      .then(async (res) => {
        setLoading(false);
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((body) => {
        updateLoginResult(body);
      })
      .catch((err) => {
        logger(`login failed: ${err}`);
        setError(true);
        if (!err) {
          setErrorMessage('Inloggen is (tijdelijk) niet mogelijk door een technische fout.'
            + ' Probeer het later nogmaals.');
        } else {
          console.log(`HTTP status: ${err.status}`);
          if (err.status === 401) {
            setErrorMessage('Wachtwoord of e-mailadres klopt niet. Controleer je invoer en probbeer nogmaals.');
          } else if (err.status === 409) {
            setErrorMessage('Ongeldige invoer. Controleer je invoer en probbeer nogmaals.');
          } else {
            setErrorMessage('Onverwachte fout. Probeer nogmaals.');
          }
        }
        setValidated(false);
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const isValid = form.checkValidity();
    setValidated(true);
    if (isValid) {
      sendLogin();
    }
  };

  if (isLoggedIn) {
    logger(`redirecting to: ${JSON.stringify(referer)}`);
    return <Redirect to={referer} />;
  }

  return (
    <Form className="loginForm" noValidate validated={validated} onSubmit={handleSubmit}>
      <Image src={logo} fluid className="logo" />
      {isError
      && <Alert key="danger" variant="danger">{errorMessage}</Alert>}
      <fieldset disabled={isLoading}>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>E-mailadres</Form.Label>
          <Form.Control
            type="email"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            minLength={6}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Wachtwoord</Form.Label>
          <Form.Control
            type="password"
            placeholder="Wachtwoord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            minLength={6}
            required
          />
        </Form.Group>
        <div className="d-grid gap-2">
          <Button variant="primary" size="lg" type="submit">
            {isLoading
            && (
              <>
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                &nbsp;
              </>
            )}
            Inloggen
          </Button>
        </div>
      </fieldset>
      <div className="signupLink">
        Geen account?
        <NavLink to="/signup">
          Registreer je dan
        </NavLink>
      </div>
      <IconAttribution keyword="coffee" author="Freepik" />
    </Form>
  );
};

export default Login;
