import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Modal,
} from 'react-bootstrap';

import { ProSettingsData } from '../../components/pro-settings/index.ts';
import ProSettingsForm from '../../components/pro-settings-form/pro-settings-form.tsx';
import { StoreContextType, useStore } from '../../store/store-context.tsx';

import './style.css';

const ProSettingsPage = () => {
  const { state: { favorite } } = useStore() as StoreContextType;
  const history = useHistory();

  let proSettings: ProSettingsData;
  if (favorite?.proSettings) {
    proSettings = favorite.proSettings;
  } else {
    // defaults
    proSettings = {
      grindSize: 5,
      extractionTime: 25,
      rating: 'neutral',
      doseIn: 18.5,
      yieldOut: 50.0,
    };
  }

  return (
    <Modal fullscreen show className="proSettingsPage">
      <Modal.Header closeButton onHide={() => history.goBack()}>
        <Modal.Title>PRO-settings wijzigen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProSettingsForm feedId={favorite!.feedId} data={proSettings} />
      </Modal.Body>
    </Modal>
  );
};

export default ProSettingsPage;
