import React from 'react';

import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';

import './style.css';

interface ProductCardProps {
  id: number,
  title: string,
  description?: string,
  imageUrl?: string,
}

const ProductCard = (props: ProductCardProps) => {
  const {
    id,
    title,
    description = undefined,
    imageUrl = undefined,
  } = props;

  return (
    <Card key={id} className="productCard">
      { imageUrl && (
      <Card.Img variant="top" src={imageUrl} />
      )}
      { !imageUrl && (
      <Card.Header><FontAwesomeIcon icon={faImage} size="6x" /></Card.Header>
      )}
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        { description && <Card.Text>{description}</Card.Text> }
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
