import React from 'react';

import './style.css';

interface DataFieldProps {
  label: string,
  children: string,
}

const DataField = ({ label, children, ...props }: DataFieldProps) => (
  <div className="data-field">
    <span className="label">{label}</span>
    <span className="value">
      {children}
    </span>
  </div>
);

export default DataField;
