import React, {
  ReactNode,
  createContext,
  useMemo,
  useReducer,
} from 'react';

import {
  initialState,
  feedReducer,
  StoreState,
  StoreAction,
} from './feed-reducer.ts';

export type StoreContextType = {
  state: StoreState,
  dispatch: React.Dispatch<StoreAction>,
}

export const StoreContext = createContext<StoreContextType | null>(null);
StoreContext.displayName = 'MyMokaAppStore';

export const useStore = () => React.useContext(StoreContext);

type StoreProviderProps = {
  children: ReactNode,
};

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const [state, dispatch] = useReducer(feedReducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);
  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};
