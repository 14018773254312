import React, { useState } from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { faFaceSmile, faFaceMeh, faFaceFrown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';

type Rating = 'good' | 'bad' | 'neutral';

export interface ProSettingsData {
  recipeId?: number,
  grindSize: number,
  doseIn: number,
  yieldOut: number,
  extractionTime: number,
  rating?: Rating,
  notes?: string,
}

interface ProSettingsProps {
  data: ProSettingsData,
}

const ProSettings = (props: ProSettingsProps) => {
  const {
    data,
  } = props;

  const {
    grindSize,
    doseIn,
    yieldOut,
    extractionTime,
    rating: brewRating = 'neutral',
    notes = undefined,
  } = data;

  const [rating, setRating] = useState(brewRating);

  const brewRatio = (yieldOut / doseIn).toFixed(1);

  let ratingIcon;
  if (rating === 'good') {
    ratingIcon = faFaceSmile;
  } else if (rating === 'bad') {
    ratingIcon = faFaceFrown;
  } else {
    ratingIcon = faFaceMeh;
  }

  const updateRating = () => {
    if (rating === 'good') {
      setRating('bad');
    } else if (rating === 'neutral') {
      setRating('good');
    } else {
      setRating('neutral');
    }
  };

  return (
    <Container className="proSettings">
      <Card>
        <Card.Title>Barista Instellingen (PRO)</Card.Title>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <div>
                  <div className="label">Maling</div>
                  <div className="value">{grindSize}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="label">Gewicht</div>
                  <div className="value">
                    {doseIn}
                    &nbsp;gram
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="label">Doorloop</div>
                  <div className="value">
                    {extractionTime}
                    &nbsp;sec
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="label">Yield</div>
                  <div className="value">
                    {yieldOut}
                    &nbsp;gram
                    <div>
                      (1&nbsp;:&nbsp;
                      {brewRatio}
                      )
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="label">Notities</div>
                  <div className="value">
                    {notes && <span>{notes}</span>}
                    {!notes && <span>-</span>}
                  </div>
                </div>
              </Col>
              <Col className="rating">
                <FontAwesomeIcon icon={ratingIcon} size="2xl" onClick={updateRating} />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProSettings;
