import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Spinner,
  Offcanvas,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsis } from '@fortawesome/free-solid-svg-icons';

import CoffeeDetail from '../../components/coffee-detail/index.ts';
import FavoriteButton from '../../components/favorite-button/index.ts';
import ProSettings from '../../components/pro-settings/index.ts';
import { StoreContextType, useStore } from '../../store/store-context.tsx';

import './style.css';

const FocusPage = () => {
  const [isLoading] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { state: { favorite } } = useStore() as StoreContextType;

  const history = useHistory();

  if (!favorite) {
    console.log('favorite not set; navigating back');
    history.goBack();
    // empty div to prevent rendering the full component.
    return <div />;
  }

  return (
    <div className="focusPage">
      <div className="header">
        <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <h3>
          Jouw koffie
        </h3>
        <div className="action">
          <FontAwesomeIcon icon={faEllipsis} size="2xl" onClick={() => setShowMenu(true)} />
        </div>
      </div>
      { isLoading && (
        <div className="body">
          <Spinner animation="border" size="sm" />
        </div>
      )}
      { !isLoading && favorite && (
        <div className="body">
          <CoffeeDetail
            coffeeName={favorite.coffeeName}
            brandName={favorite.brandName}
            brewMethod="espresso"
          />
          { favorite.proSettings && <ProSettings data={favorite.proSettings} /> }
          { !favorite.proSettings && (
            <div className="actionButtons">
              <Button onClick={() => history.push('/pro-settings')}>
                PRO Settings Toevoegen
              </Button>
            </div>
          )}
        </div>
      )}
      <Offcanvas show={showMenu} placement="bottom">
        <Offcanvas.Header closeButton onHide={() => setShowMenu(false)} />
        <Offcanvas.Body className="actionButtons">
          {favorite.proSettings && (
          <Button onClick={() => history.push('/pro-settings')}>
            Wijzig PRO Settings
          </Button>
          )}
          <FavoriteButton
            secondary
            feedId={favorite.feedId}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default FocusPage;
